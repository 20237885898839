var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap",style:({
    height: '100% !important',
  }),attrs:{"id":"signIn"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-wrap flex-shrink-0 justify-center align-center"},[_vm._m(0),_c('div',{staticClass:"px-5",staticStyle:{"min-height":"420px"},attrs:{"id":"loginCard"}},[_vm._m(1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"animate__animated animate__fadeIn",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.authenticate)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 font-weight-medium",attrs:{"type":"text","outlined":"","label":"Email address","error-messages":errors[0]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{ref:"password",staticClass:"ft mt-2 font-weight-medium",attrs:{"outlined":"","append-icon":_vm.show ? 'visibility_off' : 'visibility',"type":_vm.show ? 'text' : 'password',"label":"Password","error-messages":errors[0]},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end mt-n3",staticStyle:{"position":"relative"}},[_c('router-link',{staticClass:"ft text-decoration-none",attrs:{"to":"/forgot-password"}},[_c('span',{staticClass:"ft text-sm pa-3"},[_vm._v("Forgot password ?")])])],1),_c('v-card-actions',{staticClass:"justify-end pt-6 pl-0 pr-0 pb-2"},[_c('v-btn',{staticClass:"ft text-sm font-weight-medium",attrs:{"depressed":"","type":"submit","disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary","block":"","large":""}},[_c('span',{staticClass:"ft text-sm text-capitalize"},[_vm._v("Log in")])])],1),_c('div',{staticClass:"d-flex flex-row justify-center py-5"},[_c('span',{staticClass:"ft text-sm grey--text text--darken-1 font-weight-medium"},[_vm._v(" Don't have an account yet ?")]),_c('router-link',{staticClass:"ft ml-2 text-decoration-none",staticStyle:{"margin-top":"-3px"},attrs:{"to":"/signup/options"}},[_c('span',{staticClass:"ft text-sm"},[_vm._v("Sign up for free")])])],1)],1)]}}])})],1),_vm._m(2)]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 container-view",staticStyle:{"flex-basis":"55%"},attrs:{"id":"home-description-container"}},[_vm._m(3),_c('div',{staticClass:"d-flex animate__animated animate__fadeIn flex-column flex-grow-1 home-bg home-bg-cover",style:({
        background: ("url(" + _vm.bg + ")"),
        backgroundSize: 'cover',
        width: "auto",
        height: '100% !important',
      })})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 px-3",staticStyle:{"position":"absolute","left":"2%","top":"2%"}},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":require("@/assets/logo.jpeg"),"alt":"MSys"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animate__animated animate__fadeIn"},[_c('h1',{staticClass:"ft font-weight-bold text-lg"},[_vm._v("Hi, Welcome Back!")]),_c('p',{staticClass:"ft grey--text text--accent-1 font-weight-medium"},[_vm._v(" Please enter your details. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{staticClass:"white--text ft"},[_vm._v("MSys ®")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 mt-12 home-bg-description"},[_c('div',[_c('h1',{staticClass:"ft animate__animated animate__fadeIn font-weight-bold"},[_vm._v(" Do more with MSys ")]),_c('p',{staticClass:"ft animate__animated animate__fadeIn font-weight-medium text-md black--text pt-2"},[_vm._v(" MSys is here to help you extend the use of technology, allow you to reach your customers via Bulk SMS, Emailing, USSD etc. ")])])])}]

export { render, staticRenderFns }