








































































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { LoginState } from '@/types/types';
import { namespace } from 'vuex-class';

extend('email', {
  ...email,
  message: 'Email is not valid',
});
extend('required', {
  ...required,
  message: 'Field is required',
});

const authModule = namespace('auth');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Login extends mixins(WidgetMixins) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @authModule.Action('login') actionLogin!: any;
  username: string = '';
  password: string = '';
  show: boolean = false;
  bg: string = require('@/assets/images/events.png');

  authenticate(): void {
    this.actionLogin({
      username: this.username?.trim(),
      password: this.password,
      redirect: this.$route.query.redirect as string,
    } as LoginState & { redirect: string });
  }
}
